import { RemoteJobTable } from "@/features/remote_jobs";
import { store } from "@/store";
import { supabaseApi, useGetCurrentUserQuery, useGetPortfoliosQuery } from "@/store/services/supabase";
import { portfolioExportActions } from "@/store/slices/portfolioExport";
import { PortfolioExportProgressTracker } from "@/tools/aggregate/portfolio-export/classes/PortfolioExportProgressTracker";
import { PortfolioExportMaxSize } from "@/tools/aggregate/portfolio-export/components/PortfolioExportMaxSize";
import { PortfolioExportTableHeader } from "@/tools/aggregate/portfolio-export/components/PortfolioExportTableHeader";
import { PortfolioExportJobSchema } from "@/tools/aggregate/portfolio-export/types";
import { Tool, ToolProps } from "@/types/tools";
import { useKeys } from "@/utils/hooks/useKeys";
import { decryptData, encryptData, encryptSecret, getSecretKey } from "@/features/cryptography";
import { PortfolioExportJobTableBody } from "@/tools/aggregate/portfolio-export/components/PortfolioExportJobTableBody";
import { PortfolioExportPendingKeys } from "@/tools/aggregate/portfolio-export/components/PortfolioExportPendingKeys";
import { PortfolioExportTutorial } from "@/tools/aggregate/portfolio-export/components/PortfolioExportTutorial";
import { PortfolioPasswordInput } from "@/tools/aggregate/portfolio-export/components/PortfolioPasswordInput";
import { arrayBufferToString, hexToArrayBuffer } from "@/utils/crypto";
import { ArrowRightOutlined, Loading3QuartersOutlined, ProjectOutlined } from "@ant-design/icons";
import { useInterval, useLocalStorage } from "@mantine/hooks";
import { useSession } from "@supabase/auth-helpers-react";
import { Alert, AlertProps, Button, Modal, notification, Space, Spin } from "antd";
import { isEqual } from "lodash";
import { FC, useEffect, useRef, useState } from "react";
interface PortfolioExportToolProps extends ToolProps {
  mode?: "large-site" | "points";
  tableBodyComponent?: typeof PortfolioExportJobTableBody;
  tableHeaderComponent?: typeof PortfolioExportTableHeader;
}
export const PortfolioExportTool = ({
  mode = "points",
  tableBodyComponent = PortfolioExportJobTableBody,
  tableHeaderComponent = PortfolioExportTableHeader,
  ...props
}: PortfolioExportToolProps) => {
  const TableBody = tableBodyComponent;
  const TableHeader = tableHeaderComponent;
  const [keysReceivedState, setKeysReceivedState] = useLocalStorage<"unset" | "awaiting" | "received">({
    key: "orgKeyReceivedState",
    defaultValue: "unset"
  });
  const [progressTracker, setProgressTracker] = useState<PortfolioExportProgressTracker>();
  const [error, setError] = useState<null | {
    message: string;
    description: string[];
  }>(null);
  const [jobSchemas, _setJobSchemas] = useState<PortfolioExportJobSchema[]>([]);
  const [isJobSchemasLoading, setJobSchemasLoading] = useState(true);
  const [alerts, setAlerts] = useState<PEAlertProps[]>([]);
  const [hasJustReceivedKeys, setHasJustReceivedKeys] = useState(false);
  const [showPendingKeysModal, setShowPendingKeysModal] = useState(false);
  const refJobSchemas = useRef<PortfolioExportJobSchema[]>();
  const refProgressTracker = useRef<PortfolioExportProgressTracker>();
  const [notify, notificationContext] = notification.useNotification();
  const session = useSession();
  const [keyPair] = useKeys();
  const portfolios = useGetPortfoliosQuery(mode === "large-site");
  const {
    data: currentUser,
    refetch: refetchCurrentUser
  } = useGetCurrentUserQuery();
  const keyPairNotPresent = !keyPair.org;
  interface PEAlertProps extends AlertProps {
    key: string;
  }

  /**
   * Poll for keys every 10 seconds until we have them.
   */
  const updateKeys = async () => {
    if (currentUser?.has_org_keys) {
      refetchCurrentUserInterval.stop();
      return;
    }
    const user = await refetchCurrentUser();
    if (user.data?.has_org_keys) {
      refetchCurrentUserInterval.stop();
      return;
    }
  };
  const refetchCurrentUserInterval = useInterval(updateKeys, 10000);
  useEffect(() => {
    if (!refetchCurrentUserInterval.active && !currentUser?.has_org_keys) {
      refetchCurrentUserInterval.start();
    }
    return refetchCurrentUserInterval.stop;
  }, [refetchCurrentUserInterval]);
  useEffect(() => {
    const newAlerts: PEAlertProps[] = [];

    // When user previously accessed page without key, but now has them, show notice.
    if (keyPair.user && currentUser?.has_org_keys && (keysReceivedState === "awaiting" || hasJustReceivedKeys)) {
      setHasJustReceivedKeys(true);
      setKeysReceivedState("received");
      newAlerts.push({
        key: "received",
        message: "Keys Received",
        description: <>
            Your account has received your organisation&apos;s encryption keys. You now have full access to the
            Portfolio Export tool and can share portfolios within your group.
          </>,
        type: "success",
        showIcon: true,
        closable: !!keyPair.org,
        onClose: () => {
          setHasJustReceivedKeys(false);
        }
      });
    }
    if (keyPair.user && !currentUser?.has_org_keys) {
      // When the user accesses the page without org key, show an awaiting message.
      setKeysReceivedState("awaiting");
      newAlerts.push({
        key: "awaiting",
        message: <Space>
            <span>Awaiting Encryption Keys</span>
            <Spin className="mb-1" indicator={<Loading3QuartersOutlined spin />} size="small" />
          </Space>,
        description: <Space direction="vertical">
            You require your organisation&apos;s encryption keys to enable secure portfolio sharing. In the meantime,
            you can create private portfolios. Please try again later or ask an authorised user from your Organisation
            to log in and share the encryption keys.
            <Button onClick={() => setShowPendingKeysModal(true)} type="link" className="p-0">
              How can I receive my organisation&apos;s encryption keys? <ArrowRightOutlined />
            </Button>
          </Space>,
        type: "info",
        showIcon: true
      });
    }
    setAlerts(newAlerts);
  }, [currentUser, keyPair, keysReceivedState, hasJustReceivedKeys]);
  useEffect(() => {
    props.setIsTutorialDisabled(keyPairNotPresent);
  }, [keyPairNotPresent]);

  /** This ensures that we don't have to wait until state update to get updated value. */
  refJobSchemas.current = jobSchemas;
  refProgressTracker.current = progressTracker;

  /** Wraps _setJobSchemas to ensure the progress tracker is kept up to date. */
  const setJobSchemas = (schemas: PortfolioExportJobSchema[]) => {
    _setJobSchemas(schemas);
    if (refProgressTracker.current) {
      refProgressTracker.current.schemas = schemas;
    }
  };
  useEffect(() => {
    if (!keyPair.user) return;
    const tracker = new PortfolioExportProgressTracker(updateSchema, {
      user: keyPair.user,
      org: keyPair.org
    });
    console.log("org keypair: ", keyPair.org);
    setProgressTracker(tracker);
  }, [keyPair.user, keyPair.org]);
  useEffect(() => {
    if (!jobSchemas.length || !progressTracker) return;
    progressTracker.schemas = jobSchemas;
    progressTracker.progress().catch(err => {
      if (err.name === "AbortError") return;
      console.error("Something went wrong in progressTracker: ", err);
      setError(err);
    });
  }, [jobSchemas, progressTracker]);
  useEffect(() => {
    if (!keyPair.user) return;
    updateSchemas();
  }, [portfolios, keyPair.org, keyPair.user]);

  /**
   * Update a specific schema.
   */
  const updateSchema = (updatedSchema: PortfolioExportJobSchema) => {
    let hasUpdated = false;
    const schemas = (refJobSchemas.current || jobSchemas).map(schema => {
      if (schema.id === updatedSchema.id) {
        hasUpdated = !isEqual(schema, updatedSchema);
        return updatedSchema;
      }
      return schema;
    });
    hasUpdated && setJobSchemas(schemas);
  };

  /**
   * Matches schemas to new portfolio data.
   */
  const updateSchemas = async () => {
    const {
      user,
      org
    } = keyPair;
    if (!portfolios.data || !user || !org) {
      setJobSchemasLoading(false);
      return;
    }
    setJobSchemasLoading(true);
    const BATCH_SIZE = 4;
    const jobSchemasList: PortfolioExportJobSchema[] = [];
    const processBatch = async (batch: typeof portfolios.data) => {
      const results = await Promise.allSettled(batch.map(async item => {
        const existingSchema = (refJobSchemas.current || jobSchemas).find(({
          id
        }) => id === item.id);
        const assets = {
          errorCount: item.error || 0,
          totalCount: item.total || 0,
          processedCount: item.success || 0,
          unprocessedCount: item.unprocessed || 0
        };
        const getStatus = () => {
          if (item.pending === 0 && item.error) return "completed_with_errors";
          if (item.pending === 0) return "completed";
          if (item.total === (item.success || 0) + (item.error || 0) && item.total > 0) {
            return "completed";
          }
          if (existingSchema?.status) return existingSchema.status;
          if (assets.unprocessedCount) {
            store.dispatch(portfolioExportActions.setNewRunTotalInBatchTo({
              id: item.id!,
              count: assets.unprocessedCount
            }));
            return "uploading";
          }
          return "processing";
        };
        if (existingSchema?.status === "completed" || existingSchema?.status === "completed_with_errors") {
          return existingSchema;
        }
        const privateKey = item.is_private ? user.keyPair.privateKey : org.keyPair.privateKey;
        const secret = await getSecretKey(privateKey, item.secret_enc!);
        const name = item.name ? await decryptData(secret, hexToArrayBuffer(item.name)) : "Untitled Portfolio Export";
        const metadata = item.metadata ? JSON.parse(await decryptData(secret, hexToArrayBuffer(item.metadata))) : undefined;
        return {
          id: item.id!,
          name,
          assets,
          startTime: new Date(item.created_at!).getTime(),
          status: getStatus(),
          summaryId: item.summary_id || "",
          userId: item.user_id!,
          email: item.email!,
          groupId: item.group_id || "",
          groupName: item.group_name || "",
          secret,
          isLargeSite: mode === "large-site",
          isPrivate: item.is_private || false,
          stamps: item.stamps || [],
          metadata
        };
      }));
      const succeededSchemas = results.filter((p): p is PromiseFulfilledResult<PortfolioExportJobSchema> =>
      // exclude errored portfolios; presumably could not be decrypted
      p.status === "fulfilled" && (
      // only include completed portfolios for other users
      // to avoid multiple batch uploads & progress connection
      p.value.userId === session?.user.id || ["completed", "success", "completed_with_errors"].includes(p.value.status))).map(s => s.value);
      jobSchemasList.push(...succeededSchemas);
      setJobSchemas([...jobSchemasList]);
    };
    for (let i = 0; i < portfolios.data.length; i += BATCH_SIZE) {
      const batch = portfolios.data.slice(i, i + BATCH_SIZE);
      await processBatch(batch);
    }
    setJobSchemasLoading(false);
  };
  return <>
      {process.env.NEXT_PUBLIC_THEME_NAME?.startsWith("hkma") && <PortfolioExportMaxSize />}
      {notificationContext}

      <Modal closable onCancel={() => setShowPendingKeysModal(false)} width={540} open={showPendingKeysModal} footer={null} data-sentry-element="Modal" data-sentry-source-file="index.tsx">
        <PortfolioExportPendingKeys data-sentry-element="PortfolioExportPendingKeys" data-sentry-source-file="index.tsx" />
      </Modal>

      {alerts.map(({
      key,
      ...alert
    }) => <Alert key={key} className="mb-6 p-4" {...alert} />)}

      {error && <Alert message={error.message || "An unexpected error has occurred"} description={error.description ? <ul>
                {error.description.map((err, index) => <li key={index}>{err}</li>)}
              </ul> : "Please refresh your browser & try again. If the error persists, please contact support."} type="error" closable onClose={() => setError(null)} />}

      {keyPair.user ? <>
          {currentUser?.has_org_keys && !keyPair.org ? <PortfolioPasswordInput prompt={hasJustReceivedKeys ? "You have been granted Encryption Keys. Enter your password to confirm the exchange and unlock  portfolios shared with your group." : undefined} isLoading={keyPair.isLoading} /> : <RemoteJobTable<PortfolioExportJobSchema> headerComponent={TableHeader as FC} jobSchemas={jobSchemas} onRunStart={console.log} setJobSchemas={setJobSchemas}>
              <TableBody schemas={jobSchemas} loading={portfolios.isLoading} decrypting={isJobSchemasLoading} updateSchema={updateSchema} onDelete={async (id: string) => {
          const action = supabaseApi.endpoints.deletePortfolio.initiate(id);
          await store.dispatch(action);
          const schemas = refJobSchemas.current || jobSchemas;
          setJobSchemas(schemas.filter(schema => id !== schema.id));
        }} onEdit={async props => {
          if (!keyPair.user) return;
          let encryptedSecret = "";
          if (props.isPrivate) {
            console.log("privateee");
            encryptedSecret = arrayBufferToString(await encryptSecret({
              publicKey: keyPair.user.keyPair.publicKey,
              secret: props.schema.secret.secretAndSalt
            }));
          } else {
            if (keyPair.orgSecret && keyPair.org?.keyPair.publicKey) {
              encryptedSecret = arrayBufferToString(await encryptSecret({
                publicKey: keyPair.org.keyPair.publicKey,
                secret: props.schema.secret.secretAndSalt
              }));
            }
          }
          const details = {
            id: props.schema.id,
            name: await encryptData(props.schema.secret, props.name),
            groupId: props.groupId,
            isPrivate: props.isPrivate,
            secretEnc: encryptedSecret
          };
          const action = supabaseApi.endpoints.updatePortfolioDetails.initiate(details);
          const res = await store.dispatch(action);
          if ("error" in res) {
            console.error(res.error);
            notify.error({
              message: "Error moving portfolio",
              description: "message" in res.error ? res.error.message : "Something went wrong. Please try again."
            });
          } else {
            notify.success({
              message: "Portfolio updated!"
            });
          }
        }} onError={async (err, portfolioId) => {
          const action = supabaseApi.endpoints.deletePortfolio.initiate(portfolioId);
          await store.dispatch(action);
          const schemas = refJobSchemas.current || jobSchemas;
          setJobSchemas(schemas.filter(schema => portfolioId !== schema.id));
          setError({
            message: "Error while uploading your portfolio",
            description: [...err]
          });
        }} />
            </RemoteJobTable>}
        </> : <PortfolioPasswordInput isLoading={keyPair.isLoading} />}

      <PortfolioExportTutorial onClose={() => props.setIsTutorialOpen(false)} open={props.isTutorialOpen} setState={
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    () => {}} data-sentry-element="PortfolioExportTutorial" data-sentry-source-file="index.tsx" />
    </>;
};
export default {
  id: "portfolio-export",
  category: "aggregate",
  keyPrefix: "aggregate.portfolioExport",
  icon: <ProjectOutlined />,
  render: (props: PortfolioExportToolProps) => <PortfolioExportTool {...props} />,
  hasTutorial: true,
  showUsage: true
} as Tool;